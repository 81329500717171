import React, { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import SuccessModal from './SuccessModal';
import Button from 'react-bootstrap/Button';
import { Image } from 'react-bootstrap';
import sharelogo from './share.svg';
import { useMemo } from 'react';

const mode = process.env.NODE_ENV === 'development'
  ? 'development'
  : 'production';

const imagePrefix = {
  'development': 'http://localhost:3333/images/',
  'production': 'https://shaare.tech/images/',
}[mode];

const uploadUrl = {
  'development': 'http://localhost:3333/upload',
  'production': '/upload',
}[mode];

function App() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [link, setLink] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  // const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = useCallback(async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('image', selectedFile);

      const response = await axios.post(
        uploadUrl,
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setLink(imagePrefix + response.data.link);
      setIsShowSuccessModal(true);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedFile]);

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(link)
      .then(() => {
          // setHasError(false);
          setIsCopied(true);
      })
      .catch(err => {
          // setHasError(true);
          setIsCopied(false);
      });
  }, [link]);

  const resetState = () => {
    setSelectedFile(null);
    setLink('');
    setIsCopied(false);
    // setHasError(false);
    setIsLoading(false);
  }

  const wrapperStyles = useMemo(() => ({
    maxWidth: 'max-content',
    margin: '200px auto 0',
  }), []);

  const containerStyles = useMemo(() => ({
    fontFamily: 'Open Sans',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: 500,
    padding: '0px 15px',
    gap: 12,
  }), []);

  const titleStyles = useMemo(() => ({
    fontWeight: 700,
    fontSize: '48px',
    letterSpacing: 3,
    marginBottom: 0
  }), []);

  const subtitleStyles = useMemo(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 60
  }), []);

  const uploadWrapperStyles = useMemo(() => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: 12
  }), []);

  return (
    <div style={wrapperStyles}>
      <div style={containerStyles}>
        <Image src={sharelogo} alt="Logo" fluid />
        <h1 style={titleStyles}>Shaare.tech</h1>
        <div style={subtitleStyles}>nothing is impossible</div>
        <div style={uploadWrapperStyles}>
        {link ? (
          <>
            <h4 onClick={handleCopyToClipboard}>Your link is {link}</h4>
            <CopyToClipboard
              text={link}
              onCopy={handleCopyToClipboard}
            >
              <Button variant={isCopied ? 'success' : 'secondary'}>
                {isCopied ? 'Copied' : 'Copy to clipboard'}
              </Button>
            </CopyToClipboard>
            {isCopied && (
              <Button variant={'light'} onClick={resetState}>
                Try again
              </Button>
            )}
          </>
        ) : (
          <div className="input-group mb-3">
            <input
              type="file"
              className="form-control"
              id="inputGroupFile02"
              onChange={handleFileChange}
              accept="image/*"
            />
            <Button
              onClick={handleUpload}
              variant={'primary'}
              style={{ maxWidth: 200 }}
              disabled={!selectedFile || isLoading}
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: 6 }}
                  ></span>
                  {'Loading...'}
                </>
              ) : 'Upload'}
            </Button>
          </div>
        )}
        </div>
      </div>
      <SuccessModal
        show={isShowSuccessModal && link}
        handleClose={() => setIsShowSuccessModal(false)}
        link={link}
      />
    </div>
  );
}

export default App;
